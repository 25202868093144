<template lang="">
    <Dialog :style="{ width: '450px' }" :header="`Editando ${formLocal.funcionario.name}`" class="p-fluid" :modal="true">
        <div class="field">
            <label for="emailFuncionario">E-mail</label>
            <InputText
                id="emailFuncionario"
                v-model.trim="formLocal.emailFuncionario"
                placeholder="Email"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !formLocal.emailFuncionario }"
            />
            <small class="p-error" v-if="submitted && !formLocal.emailFuncionario">O email do funcinoário é obrigatório.</small>
        </div>
        <div class="field">
            <label for="telefoneFuncionario">Telefone</label>
            <InputMask
                id="telefoneFuncionario"
                v-model.trim="formLocal.telefoneFuncionario"
                placeholder="Telefone"
                mask="(99) 99999-9999"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !formLocal.telefoneFuncionario }"
            />
            <small class="p-error" v-if="submitted && !formLocal.telefoneFuncionario">O telefone do funcinoário é obrigatório.</small>
        </div>

        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClickClose" />
            <Button class="p-button p-button-primary ml-3" :loading="loadingSalvar" icon="pi pi-check" label="Salvar" @click="onClickSalvar" />
        </template>
    </Dialog>
</template>
<script>
import ConvocacaoFuncionarioService from '../services/ConvocacaoFuncionarioService';
import { showSuccess, showError } from '@/utils/Toast'

export default {
    name: 'ConfirmacaoContatoDialog',
    emits: ['onClose', 'onSalvar'],
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'form',
        event: 'formchange'
    },
    computed: {
        formLocal: {
            get: function () {
                return this.form || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        }
    },
    data() {
        return {
            $service: null,
            loadingSalvar: false,
            submitted: false
        };
    },
    mounted() {
        this.$service = new ConvocacaoFuncionarioService();
    },
    methods: {
        onClickClose() {
            this.$emit('onClose');
        },
        async onClickSalvar() {
            try {
                this.loadingSalvar = true;
                await this.$service.save(this.form);
                this.loadingSalvar = false;
                showSuccess(this.$toast, 'Alterado com sucesso');
                this.$emit('onSalvar');
            } catch (error) {
                this.loadingSalvar = false;
                showError(this.$toast, error);
            }
        }
    }
};
</script>
