<template lang="">
    <div>
        <DataTable
            :loading="loading"
            dataKey="id"
            :value="records"
            filterDisplay="menu"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            selectionMode="single"
            :rowClass="defaultRowClass"
            @sort="onSort"
            sortField="id"
            :sortOrder="1"
            removableSort
        >
            <template #empty> Nenhum registro encontrado. </template>

            <Column field="msgErro" :style="{ padding: '1rem 0.5rem' }">
                <template #body="slotProps">
                    <i
                        v-if="slotProps.data.msgErro"
                        v-tooltip.bottom="slotProps.data.msgErro"
                        class="pi pi-info-circle"
                        :style="{ color: 'red' }"
                    ></i>
                </template>
            </Column>

            <Column field="funcionario.name" :sortable="true" sortField="funcionario.name" header="Funcionario"></Column>
            <Column field="telefoneFuncionario" :sortable="true" sortField="telefoneFuncionario" header="Telefone funcionário"></Column>
            <Column field="emailFuncionario" :sortable="true" sortField="emailFuncionario" header="E-mail funcionário"></Column>
            <Column bodyClass="text-left" headerStyle="width: 5rem;">
                <template #body="slotProps">
                    <Button icon="pi pi-pencil" class="p-button-text p-button-info" v-tooltip="'Editar'" @click="onClickEditRecord(slotProps.data)" />
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-show="!loading"
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>

        <div class="grid">
            <div class="col"></div>
            <div class="col">
                <div class="grid mt-2">
                    <div class="field-checkbox">
                        <InputSwitch id="binary" v-model="convocacao.gerarPreSolicitacao" />
                        <label for="binary">Gerar pré-solicitação?</label>
                    </div>
                    <div class="field-checkbox">
                        <InputSwitch id="pcd" v-model="convocacao.gerarAgendamento" />
                        <label for="pcd">Gerar agendamento?</label>
                    </div>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button
                            label="Processar convocação"
                            :disabled="!convocacao.gerarPreSolicitacao && !convocacao.gerarAgendamento"
                            :loading="loadingProcessarConvocacao"
                            icon="pi pi-check"
                            class="p-button p-button-primary mr-2 w-30rem"
                            @click="onClickProcessarConvocacao"
                        />
                        <Button label="Cancelar" icon="pi pi-times" class="p-button p-button-danger" @click="showCancelarRecord = true" />

                        <AppDeleteDialog
                            v-model:visible="showCancelarRecord"
                            customTitle="Deseja realmente cancelar a convocação?"
                            labelConfirm="Cancelar"
                            @onConfirm="onClickCancelar"
                            @onClose="onCloseCancelar"
                        >
                        </AppDeleteDialog>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmacaoContatoDialog
            v-if="showConfirmarContatos"
            v-model:visible="showConfirmarContatos"
            v-bind:form="actionItem"
            @onClose="showConfirmarContatos = false"
            @onSalvar="onSalvarEditFuncionario"
        />
    </div>
</template>
<script>
import ConvocacaoFuncionarioService from '../services/ConvocacaoFuncionarioService';
import ConvocacaoService from '../services/ConvocacaoService';
import { showSuccess, showError } from '@/utils/Toast';
import ConfirmacaoContatoDialog from './ConfirmacaoContatoDialog';
import { clone } from 'ramda';

export default {
    components: { ConfirmacaoContatoDialog },
    data() {
        return {
            convocacao: {
                gerarPreSolicitacao: true,
                gerarAgendamento: false
            },
            $service: null,
            $serviceConvocacao: null,
            loading: false,
            loadingProcessarConvocacao: false,
            showObsDialog: false,
            showConfirmarContatos: false,
            records: [],
            actionItem: {},
            total: 0,
            page: 1,
            perPage: 10,
            sort: null,
            filter: [],
            showCancelarRecord: false,
            filtrosExtras: {
                clienteIds: null,
                unidadeIds: null,
                funcionarioIds: null,
                ignorarExcluidos: true
            }
        };
    },
    watch: {
        'convocacao.gerarAgendamento'() {
            if (this.convocacao.gerarAgendamento) {
                this.convocacao.gerarPreSolicitacao = true;
            }
        },
        'convocacao.gerarPreSolicitacao'() {
            if (!this.convocacao.gerarPreSolicitacao) {
                this.convocacao.gerarAgendamento = false;
            }
        }
    },
    mounted() {
        this.$service = new ConvocacaoFuncionarioService();
        this.$serviceConvocacao = new ConvocacaoService();
        this.load();
    },
    methods: {
        async onClickCancelar() {
            try {
                await this.$serviceConvocacao.cancelar(this.$route.params.id);
                showSuccess(this.$toast, 'Cancelado com sucesso');
                this.showCancelarRecord = false;
                this.$router.push(`/gestaoMedica/request`);
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        onCloseCancelar() {
            this.showCancelarRecord = false;
        },
        defaultRowClass(data) {
            if (data.excluido) {
                return 'row-excluida';
            }

            return '';
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findAllFuncionario(this.$route.params.id, {
                    limit: this.currentPerPage,
                    page: this.page,
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras: this.filtrosExtras
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        onSort(event) {
            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                      ?.split(/(?=[A-Z])/)
                      .join('_')
                      .toLowerCase()}`
                : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.load();
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        onClickEditRecord(record) {
            this.actionItem = clone(record);
            this.showConfirmarContatos = true;
        },
        onSalvarEditFuncionario() {
            this.showConfirmarContatos = false;
            this.load();
        },
        async onClickProcessarConvocacao() {
            try {
                await this.$serviceConvocacao.processarConvocacao(this.$route.params.id, this.convocacao);
                this.$router.push(`/convocacao-exames/${this.$route.params.id}/processando-solicitacao`);
            } catch (error) {
                showError(this.$toast, error);
                this.load();
            }
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep(.row-inactive) {
    color: #9e9e9e !important;
}
::v-deep(.row-excluida) {
    color: #9e9e9e !important;
    text-decoration: line-through;
}
</style>
